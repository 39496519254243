// components
import { Button, ButtonGroup, Paper, Typography, CircularProgress } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { Div } from 'components';

// hooks
import { useStyles } from 'hooks';
import { useState } from 'react';

// styles
import styles from './styles';

const feedbackLink = 'https://forms.gle/mxkskSfZpKZbqnuf6';

const ProfileSummary = ({
  info
}) => {
  const classes = useStyles(styles);
  const [loader, showLoader] = useState(false);
  const onDirections = () => window.open(`https://maps.google.com/?q=${info.latitude},${info.longitude}`);

  return (
    <Div className={classes.root}>
      <a href={feedbackLink} className={classes.newfeedbackLink} target="_blank">Give us Feedback!</a>
      <Paper className={classes.avatar} component='span'>
        <img src={info?.image_url ? info?.image_url : '/logo192.png'} className={classes.image} />
      </Paper>
      <Div className={classes.summary}>
        <Typography className={classes.name}>
          {info?.name}
        </Typography>
{/*
        <Typography className={classes.speciality}>
          {info?.speciality}
        </Typography>
        <Typography className={classes.experience}>
          {info?.experience}
        </Typography>
*/}
        <Typography className={classes.services}>
          {info?.description}
        </Typography>
        <Typography className={classes.services}>
          {info?.address}
        </Typography>
        {
          info?.contact_no ? (
            <Typography className={classes.fees}>
              <label className={classes.label}>Contact: <a href={'tel:'+info?.contact_no}>{info?.contact_no}</a></label>
            </Typography>
          ) : ('')
        }
        {
          info?.latitude && info?.longitude ? (
            <Div className={classes.btnGroupWrapper}>
              <ButtonGroup
                variant='contained'
                color='primary'
                classes={
                  {
                    root: classes.buttonGroup
                  }
                }
                aria-label='contained primary button group'
              >
                <Button
                  variant='outlined'
                  className={classes.getDirections}
                  onClick={onDirections}
                >
                  Locate on Google Maps
                </Button>
              </ButtonGroup>
            </Div>
          ) : ('')
        }    
{/*
        <a href={feedbackLink} className={classes.feedbackLink} onClick={showLoader}>Give us Feedback!
          <Button color='primary' variant='contained' className={classes.feedbackBtn}>
            {loader ? <CircularProgress className={classes.loader} size={20}/> : null} Feedback
          </Button>
        </a>
*/}
      </Div>
    </Div>
  );
};

export default ProfileSummary;
