import { GpsFixed } from "@material-ui/icons";

const styles = ({ breakpoints, palette }) => ({
  root: {
    border: `1px solid ${palette.border.main}`,
    background: palette.common.white,
    padding: 10,
    margin: 10,
    display: 'flex',
    flexDirection: 'row',
    [breakpoints.down('sm')]: {
      marginTop: 40,
      alignItems: 'center',
      flexDirection: 'column'
    }
  },
  avatar: {
    width: 170,
    minHeight: 100,
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      position: 'relative',
      top: -40,
      width: 80,
      minHeight: 40
    }
  },
  image: {
    padding: 20,
    width: '100%',
    objectFit: 'contain',
    [breakpoints.down('sm')]: {
      padding: 5
    }
  },
  summary: {
    paddingLeft: 20,
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      position: 'relative',
      top: -20,
      paddingLeft: 0
    }
  },
  name: {
    fontSize: 20,
    fontWeight: 600,
  },
  speciality: {
    fontSize: 15,
    fontWeight: 600,
    color: palette.text.secondary
  },
  experience: {
    fontSize: 15,
    fontWeight: 600,
    color: palette.text.secondary
  },
  services: {
    fontSize: 14,
    marginTop: 12
  },
  fees: {
    fontSize: 12.5,
    marginTop: 12
  },
  feedbackLink: {
    textDecoration: 'none',
    margin: '15px auto',
    display: 'inline-block'
  },
  newfeedbackLink: {
    textDecoration: 'underline',
    position: 'absolute',
    right: 20,
    fontWeight: 600
  },
  loader: {
    color: '#ffffff',
    marginRight: 12,
  },
  feedbackBtn: {
    width: 130,
    textTransform: 'capitalize'
  },
  btnGroupWrapper: {
    padding: '20px 0px 10px'
  },
  buttonGroup: {
  },
  getDirections: {
    textTransform: 'capitalize',
    wordBreak: 'break-all'
  },
  label: {
    fontWeight: 600
  },
});

export default styles;