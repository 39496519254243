import { api } from 'services';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: null
};

const getDetail = ({ detail }) => detail.data;
const getDetailError = ({ detail }) => detail.error;
const getDetailLoading = ({ detail }) => detail.loading;

const selectDetailData = createSelector(getDetail, data => data);
const selectDetailError = createSelector(getDetailError, error => error);
const selectDetailLoading = createSelector(getDetailLoading, loading => loading);

const fetchDetail = createAsyncThunk(
  'detail/fetchDetail',
  async (partnerId, thunkAPI) => {
    const data = await api.get(`/accounts?partner_id=${partnerId}`);
    console.log(data);
    return data;
  }
);

const detail = createSlice({
  name: 'detail',
  initialState,
  reducers: {
    // no static reducers
  },
  extraReducers: {
    [fetchDetail.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchDetail.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [fetchDetail.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  }
});

export const actions = {
  fetchDetail
};

export const selectors = {
  selectDetailData,
  selectDetailError,
  selectDetailLoading
};

export default detail.reducer;